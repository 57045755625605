import { Controller } from "stimulus"
import { createClient } from 'microcms-js-sdk';
import { moment } from 'moment';
import cheerio from 'cheerio';

const client = createClient({
  serviceDomain: 'soudanmadoguchi',
  apiKey: '8fdbb9d9236d40328adb90e88c740a599f91',
});

export default class extends Controller {
  static targets = [
    'title', 'subtitle', 'image', 'content', 'publishedAt', 'breadcrumb',
    'outline', 'tags'
  ]

  connect() {
    const searchParams = new URLSearchParams(window.location.search);
    client
      .getListDetail({
        endpoint: 'articles',
        contentId: searchParams.get('id')
      })
      .then((article) => {
        // metaタグ
        this.setMetaTags(article)
        const $ = cheerio.load(article.content_html);
        // パンくず
        this.renderBreadcrumb(article)
        // 目次
        this.renderOutline(article, $)
        this.titleTargets.forEach((target, index) => {
          target.textContent = article.title;
        });
        // TOP画像
        this.imageTarget.src = article.top_image?.url;
        // コンテンツ
        this.renderContent(article, $)
        // タグ
        this.renderTags(article)
      })
      .catch((err) => console.log(err));
  }

  setMetaTags(article) {
    document.title = article.title;
    document.description = article.subtitle;
    document.querySelector('meta[name="description"]').setAttribute("content", article.subtitle);
    document.querySelector('meta[name="keywords"]').setAttribute(
      "content",
      `生命保険, 損害保険, 栃木県, 足利市, ほけんの相談窓口, 保険相談, 足利市田島町, アシコタウンあしかが, オンライン相談, 店舗相談, 訪問相談, 両毛地区, ${article.category?.name}`
    );
    document.querySelector('meta[property="og:title"]').setAttribute(
      "content",
      article.title
    );
    document.querySelector('meta[property="og:description"]').setAttribute(
      "content",
      article.subtitle
    );
    document.querySelector('meta[property="og:image"]').setAttribute(
      "content",
      article.top_image?.url
    );
  }

  renderBreadcrumb(article) {
    const category = article.category
    const categoryUrl = `/articles.html?category=${category.id}`
    this.breadcrumbTarget.insertAdjacentHTML('afterbegin',`
      <a href="/">TOP</a> >
      <a href="/articles.html">お役立ちコラム</a> >
      <a href=${categoryUrl}>${category.name}</a> >
      ${article.title}
    `);
  }


  renderOutline(article, $) {
    const headings = $('h2').toArray();
    const toc = headings.map(data => ({
      text: data.children[0].data || data.children[0].children[0].data,
      id: data.attribs.id,
      name: data.name
    }));
    this.outlineTarget.insertAdjacentHTML('afterbegin',`
      <div class="font-bold text-size-16 text-textBlack">目次</div>
    `);

    toc.forEach((table, i) => {
      if (!table.text) return
      this.outlineTarget.insertAdjacentHTML('beforeend',`
        <div><a href=${"#" + table.id} class="text-size-14 text-textBlack mt-4">${table.text}</a></div>
      `);
    });

  }

  renderContent(article, $) {
    $('h2').addClass('text-size-18 font-bold -mt-[64px] pt-[100px] mb-5');
    $('h2').after('<hr class="h-2 bg-main" />')
    $('h3').addClass('text-size-16 font-bold mt-[20px] mb-10');
    $('p').addClass('text-size-16 text-textBlack my-10 tracking-wide leading-6')
    $('img').addClass('w-full my-10')
    $('li').addClass('my-3 text-size-12 font-bold text-textGray')
    this.contentTarget.insertAdjacentHTML('afterbegin',$.html());
  }

  renderTags(article) {
    this.tagsTarget.insertAdjacentHTML('afterbegin',`
      <div class="text-size-16 font-bold lg:mt-[80px] sm:mt-[40px] mb-10">このコラムに関するキーワード</div>
    `);

    article.tags.forEach((tag, i) => {
      const url = `/articles.html?tagId=${tag.id}`
      this.tagsTarget.insertAdjacentHTML('beforeend',`
        <a href=${url} class="bg-mainLight py-4 px-8 mr-4 mb-4 text-size-8 rounded-2xl text-textLightGray">
          # ${tag.name}
        </a>
      `);
    })
  }
}
