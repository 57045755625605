import { Controller } from "stimulus"
import { createClient } from 'microcms-js-sdk';
import _ from 'lodash';

const client = createClient({
  serviceDomain: 'soudanmadoguchi',
  apiKey: '8fdbb9d9236d40328adb90e88c740a599f91',
});

const DEFAULT_FILTERS = ''

const ARTICLES_FIELDS = 'id,title,subtitle,top_image,category,isPickup'
const RANKING_ARTICLES_FIELDS = 'id,title,subtitle,top_image,category'
const CATEGORIES_FIELDS = ''
const TAGS_FIELDS = ''

const CATEGORIES_LIMIT = 12
const TAGS_LIMIT = 12
const RANKING_ARTICLES_LIMIT = 3
const ARTICLES_LIMIT = 100

const DEFAULT_KEYWORD = ''

export default class extends Controller {
  static targets = ['list', 'pickup', 'ranking', 'tags', 'categories', 'breadcrumb', 'keyword']

  connect() {
    const searchParams = new URLSearchParams(window.location.search);
    const categoryId = searchParams.get('categoryId')
    const tagId = searchParams.get('tagId')
    const keyword = searchParams.get('keyword')
    console.log('keyword', keyword);
    const filters = this.getFilters(categoryId, tagId)
    console.log('filters', filters);

    this._getContents('categories', DEFAULT_FILTERS, CATEGORIES_FIELDS, CATEGORIES_LIMIT, DEFAULT_KEYWORD, (categories) => {
      if (this.hasCategoriesTarget) this.renderCategories(categories)
      this._getContents('tags', DEFAULT_FILTERS, TAGS_FIELDS, TAGS_LIMIT, DEFAULT_KEYWORD, (tags) => {
        if (this.hasTagsTarget) this.renderTags(tags)
        this._getContents('articles', DEFAULT_FILTERS, RANKING_ARTICLES_FIELDS, RANKING_ARTICLES_LIMIT, DEFAULT_KEYWORD, (rankingArticles) => {
          if (this.hasRankingTarget) this.renderRanking(rankingArticles)
          this._getContents('articles', filters, ARTICLES_FIELDS, ARTICLES_LIMIT, keyword, (articles) => {
            const categoryName = this.getFilterName({ categories, tags, categoryId, tagId, keyword })
            if (this.hasPickupTarget) this.renderPickup(articles, categoryName)
            if (this.hasBreadcrumbTarget) this.renderBreadcrumb(categoryName)
            if (this.hasListTarget) this.renderList(articles, categoryName)
          });
        });
      });
    });
  }

  getFilters(categoryId, tagId) {
    var filters = ''
    if (categoryId) var filters =`category[equals]${categoryId}`
    if (tagId) var filters = `tags[contains]${tagId}`
    return filters
  }

  search() {
    const keyword = this.keywordTarget.value
    location.href = `/articles.html?keyword=${keyword}`
  }

  renderList(contents, categoryName) {
    // サーチページの場合はピックアップコラムも含める
    const filteredContents = (!categoryName)
      ? contents.filter(c => !c.isPickup)
      : contents

    // タイトル部分
    if (categoryName) {
      this.listTarget.insertAdjacentHTML('afterbegin',`
        <div class="col-span-12 sm:mx-5">
          <h1 class="text-size-18 font-bold font-gothic">
            ${categoryName}に関するコラム一覧
          </h1>
        </div>
      `);
    }
    filteredContents.forEach((content, i) => {
      const detailUrl = `/article.html?id=${content.id}`
      this.listTarget.insertAdjacentHTML('beforeend',`
        <a href=${detailUrl} class="lg:col-span-4 sm:col-span-6 relative lg:h-150 sm:h-140 lg:mr-10 sm:mx-5 lg:mt-15 sm:mt-10">
          <img class="lg:h-60 w-[100%] object-cover" src=${content.top_image.url}>
          <div class="font-gothic mt-7 text-size-12 font-bold tracking-wide leading-6">
            ${content.title}
          </div>
          <div class="font-gothic lg:mt-5 sm:mt-2 text-size-8 text-textLightGray tracking-wide leading-5">
            ${content.subtitle}
          </div>
          <div class="border mt-6 rounded-xl text-textLightGray text-size-8 text-right font-gothic py-1 px-5 absolute bottom-0 right-0">
            ${content.category?.name}
          </div>
        </a>
      `);
    });
  }

  renderBreadcrumb(categoryName) {
    if (!categoryName) {
      this.breadcrumbTarget.classList.add('is-hidden')
      return
    }
    this.breadcrumbTarget.classList.remove('is-hidden')
    this.breadcrumbTarget.insertAdjacentHTML('afterbegin',`
      <a href="/">TOP</a> >
      <a href="/articles.html">お役立ちコラム</a> >
      ${categoryName}
    `);
  }

  renderPickup(contents, categoryName) {
    const content = _.sample(contents.filter(c => c.isPickup)) || null
    if (!content || categoryName) {
      this.pickupTarget.classList.add('is-hidden')
      return
    }

    const detailUrl = `/article.html?id=${content.id}`
    this.pickupTarget.insertAdjacentHTML('afterbegin',`
      <div class="lg:h-[400px] grid grid-cols-12 lg:!mt-6 lg:bg-mainLight">
        <div class="lg:col-start-3 lg:col-span-8 sm:col-span-12">
          <a href=${detailUrl}>
            <div class="grid lg:grid-cols-3 sm:relative lg:mt-20">
              <div class="sm:w-full lg:col-span-2">
                <img class="w-full object-cover lg:h-[320px] sm:h-[230px]" src=${content.top_image.url}>
              </div>
              <div class="lg:col-span-1 sm:hidden bg-white lg:px-12 lg:py-21 lg:h-[320px] relative">
                <div class="text-2xl font-bold font-lato tracking-widest" style="text-align: center;">
                  PICK UP
                </div>
                <div class="font-gothic mt-14 text-size-13 font-bold tracking-wide leading-7">
                  ${content.title}
                </div>
                <div class="font-gothic mt-10 text-size-8 text-textLightGray tracking-wide leading-5">
                  ${content.subtitle}
                </div>
                <div class="border mt-8 rounded-xl text-textLightGray text-size-8 text-right font-gothic py-1 px-5 absolute bottom-20 right-15">
                  ${content.category?.name}
                </div>
              </div>
              <div class="lg:hidden text-white sm:absolute sm:bottom-5 text-size-16 font-gothic font-bold px-4">
                ${content.title}
              </div>
            </div>
          </a>
        </div>
      </div>
    `);
  }

  renderRanking(contents) {
    if (contents.length === 0) return
    this.rankingTarget.insertAdjacentHTML('afterbegin',`
      <div class="text-size-14 font-bold font-gothic sm:mt-20">
        人気コラムランキング
      </div>
      <hr class="my-5 text-black h-[2px]"/>
    `);
    contents.forEach((content, i) => {
      const detailUrl = `/article.html?id=${content.id}`
      this.rankingTarget.insertAdjacentHTML('beforeend',`
        <a href=${detailUrl} class="grid grid-cols-12 mb-4">
          <div class="lg:col-span-4 sm:col-span-3 relative">
            <div
              class="absolute top-0 left-0 text-size-6 text-white h-14 w-14"
              style="border-top:14px solid #D09C9C;border-right:14px solid transparent;border-bottom:14px solid transparent;border-left:14px solid #D09C9C;"
            >
            </div>
            <img class="h-30 object-cover w-[100%]" src=${content.top_image.url}>
            <div class="absolute top-1 left-3 text-white text-size-9 font-bold">${i + 1}</div>
          </div>
          <div class="lg:col-span-8 sm:col-span-9 pl-4">
            <div class="text-size-9 font-gothic font-bold">
              ${content.title}
            </div>
            <div class="text-size-6 text-textLightGray mt-4">
              ${content.category?.name}
            </div>
          </div>
        </a>
      `);
    })
  }

  renderTags(tags) {
    this.tagsTarget.insertAdjacentHTML('afterbegin',`
      <div class="text-size-14 font-bold font-gothic mt-30">
        タグから探す
      </div>
      <hr class="my-5 text-black h-[2px]"/>
    `);

    const tagList = document.createElement('div');
    tagList.className = 'flex flex-wrap mt-10';

    this.tagsTarget.appendChild(tagList);

    tags.forEach((tag, i) => {
      const url = `/articles.html?tagId=${tag.id}`
      tagList.insertAdjacentHTML('beforeend',`
        <a href=${url} class="bg-mainLight py-4 px-8 mr-4 mb-4 text-size-8 rounded-2xl text-textLightGray">
          # ${tag.name}
        </a>
      `);
    })
  }

  renderCategories(categories) {
    this.categoriesTarget.insertAdjacentHTML('afterbegin',`
      <div class="text-size-14 font-bold font-gothic mt-40">
        カテゴリから探す
      </div>
      <hr class="my-5 text-black h-[2px]"/>
    `);

    const categoryList = document.createElement('div');
    categoryList.className = 'grid grid-cols-12 px6';

    this.categoriesTarget.appendChild(categoryList);

    categories.forEach((category, i) => {
      const url = `/articles.html?categoryId=${category.id}`
      categoryList.insertAdjacentHTML('beforeend',`
        <div class="lg:col-span-12 sm:col-span-6">
          <a href=${url} class="grid grid-cols-12 px-6 mb-5">
            <div class="lg:col-span-2 sm:col-span-3">
              <i class="fas ${category.icon_id} text-main mt-1"></i>
            </div>
            <div class="lg:col-span-10 sm:col-span-9">
              <div class="text-size-14 text-textGray">${category.name}</div>
            </div>
          </a>
        </div>
      `);
    })
  }

  getFilterName(key) {
    if (!key.categoryId && !key.tagId && !key.keyword) {
      return ''
    }
    console.log('key',key );

    if (key.categoryId) {
      return key.categories.find(c => c.id === key.categoryId).name
    } else if (key.tagId) {
      return key.tags.find(t => t.id === key.tagId).name
    } else {
      return key.keyword
    }
  }

  _getContents(endpoint, filters, fields, limit, keyword, callback) {
    this._callGetListApi({ endpoint, filters, fields, keyword, limit }, callback);
  }

  _callGetListApi(key, callback) {
    client
      .getList({
        endpoint: key.endpoint,
        queries: {
          filters: key.filters,
          fields: key.fields,
          limit: key.limit,
          q: key.keyword
        },
      })
      .then((res) => {
        callback(res.contents);
      })
  }
}
