// faq_controller.js
import { Controller } from "stimulus";
import axios from 'axios'

const API_URL = location.host === 'localhost:9000' ? 'http://api.sakura_family.test' : 'https://api.sakura-family.com'
const TOKEN = location.host === 'localhost:9000' ? '3212736735d942d4cababf541005ec1e5d92a239ad52312b5349087caf9c6593' : '34997a76ae9093d482a827e2c86295c4862a43ccaa048969ee040fb8be6028b6'

export default class extends Controller {
  static targets = [ "title", "content" ]


  initialize() {
    const titles = this.titleTargets;
    const contents = this.contentTargets;
    axios.get(API_URL + '/api/v1/shops/faqs', {
      headers: {
        "Authorization": `Bearer ${TOKEN}`
      }
    })
    .then(function (response) {
      const categories = response.data;

      // タイトル
      titles.forEach((title) => {
        const categoryId = title.dataset.id;
        const category = categories.find((c) => {
          return c.id == categoryId
        })
        title.textContent = category.name;
      });

      contents.forEach((content) => {
        const categoryId = content.dataset.id;
        const limit = content.dataset.limit;
        const category = categories.find((c) => {
          return c.id == categoryId
        })

        const elements = category.faqs.reverse().some((faq, index) => {
          if (limit && index == 6) {
            return true;
          }
          content.insertAdjacentHTML('afterbegin',`
            <div class="faq-content" data-controller="toggle">
              <a data-action="toggle#toggleAnswer" class="columns is-vcentered is-flex-touch faq-content-list">
                <div class="column is-1 is-2-touch">
                  <img class="faq-content-list-icon" src="assets/img/icon_question.png">
                </div>
                <div class="column is-10 is-8-touch faq-content-list-text">
                  ${faq.question}
                </div>
                <div class="column is-1 is-2-touch">
                  <img class="faq-content-list-arrow" src="assets/img/icon_arrow_under.png" data-toggle-target="arrow">
                </div>
              </a>
              <div class="columns is-vcentered faq-content-list-answer" data-toggle-target="answer">
                <div class="column is-1 is-2-mobile">
                  <img class="faq-content-list-icon" src="assets/img/icon_answer.png">
                </div>
                <div class="column is-10 is-9-mobile faq-content-list-answer-text">
                  ${faq.answer}
                </div>
              </div>
            </div>
          `);
        });


      });
    })

  }

}
