// faq_controller.js
import { Controller } from "stimulus";
import axios from 'axios'

const API_URL = location.host === 'localhost:9000' ? 'http://api.sakura_family.test' : 'https://api.sakura-family.com'
const TOKEN = location.host === 'localhost:9000' ? '3212736735d942d4cababf541005ec1e5d92a239ad52312b5349087caf9c6593' : '34997a76ae9093d482a827e2c86295c4862a43ccaa048969ee040fb8be6028b6'

export default class extends Controller {
  static targets = [ "title", "content" ]


  initialize() {
    const titles = this.titleTargets;
    const contents = this.contentTargets;
    axios.get(API_URL + '/api/v1/shops/products', {
      headers: {
        "Authorization": `Bearer ${TOKEN}`
      }
    })
    .then(function (response) {
      const categories = response.data;

      // タイトル
      titles.forEach((title) => {
        const categoryId = title.dataset.id;
        const category = categories.find((c) => {
          return c.id == categoryId
        })
        title.textContent = category.name;
      });

      // 一覧
      contents.forEach((content) => {
        const columnClassName = ['/service.html', '/online_consultation.html'].includes(location.pathname) ? 'is-3-desktop is-4-touch' : 'is-4'
        const categoryId = content.dataset.id;
        const category = categories.find((c) => {
          return c.id == categoryId
        })
        const products = category.products.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });
        products.reverse().some((product, index) => {
          content.insertAdjacentHTML('afterbegin',`
            <a href="https://www.aflac.co.jp/" class="is-flex-tablet service-insurance-list">
              <div class="service-insurance-logo">
                <img class="service-insurance-logo-img" src=${product.logo_url}>
              </div>
              <div class="service-insurance-detail">
                <div class="service-insurance-detail-title">
                  ${product.name}
                </div>
                <div class="service-insurance-detail-text">
                  ${product.description}
                </div>
              </div>
            </a>
            <hr class="is-hidden-desktop service-insurance-bottom-border">
          `);
        });

      });
    })
  }

}
